:root {
  --sk-size: 12px;
  --sk-color: white;
}


.sk-flow {
  width: 60px ;
  display: flex;
  justify-content: space-between;
  
}

.sk-flow-dot {
  width: var(--sk-size);
  height:  var(--sk-size);
  background-color: var(--sk-color);
  border-radius: 100%;
  animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
}

.sk-flow-dot:nth-child(1) { animation-delay: -0.30s; }
.sk-flow-dot:nth-child(2) { animation-delay: -0.15s; }

@keyframes sk-flow {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); 
  }
}