h1{
    color:white;
}

.mainpage-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: white;
    font-family: 'Arial', sans-serif;
}

.response{
    color: black;
}

.left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right{
    width: 50%;
}

.submit{
    width: 10%;
    border: none;
    margin-top: 20px;
    border-radius: 2px;
    height: 20px;
}