.hover-tooltip {
    position: relative;
    display: inline-block;
}

.hover-tooltip .tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: white;
    color: black;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 50%; /* Position vertically centered */
    left: 100%; /* Position to the right of the element */
    margin-left: 10px; /* Add some space between the element and the tooltip */
    transform: translateY(-50%); /* Center the tooltip vertically */
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
}

.hover-tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}