body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--lt-body-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --lt-primary: #014181; /**@NEW */
  --lt-body-bg: #3c3232; /**@NEW */
  --lt-bg-dynamic: #3c3232; /**@NEW */
  --lt-header-dynamic: #3c3232; /**@NEW */
  --lt-text-dynamic: #000000; /**@NEW */
  --lt-button-dynamic: #0a66c2; /**@NEW */
  --lt-secondry: #04535f;
  --lt-danger: #F44336;
  --lt-warn: #FDB93F;
  --lt-orange: #FF933A;
  --lt-light: #FFFFFF; /**@NEW */
  --lt-blue: #012253;
  --lt-light-blue: #1957B4;
  --lt-dark-blue: #1E293B;
  --lt-green: #2e7d32;
  --lt-success: #4caf50;
  --lt-border-color: #D9E5F0;
  --lt-input-border-color: #CED4DA;
  --lt-light-green: #20c997;
  --lt-header-bg: #F4F3F9;
  --lt-light: #FFF;
  --lt-light-warn: #FBEDBA;
  --lt-black: #1E1E1E;
  --lt-gray: #92A0B3;
  --lt-bg-color: #EDEBE8; /**@NEW */
  --lt-light-gray: #f5f5f5;
  --lt-text-color: #343A40;
  --lt-text-gray: #74788D;
  --lt-text-gray-2: #7E7E7E;
  --lt-btnPrimary: #0a66c2; /**@NEW */
  --lt-btnPrimaryHover: rgb(13, 127, 242);  /**@NEW*/
  --lt-text-primary:rgb(10, 102, 194);
  --lt-place-holder: rgba(52, 76, 122, 0.5);
  --lt-box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
  --lt-sidebar-bg: #f0eff0;
  --lt-sidebar-menu: rgba(0, 0, 0, 0.05);
  --lt-toobarcolor: rgb(4, 83, 95);
  --lt-tooltip: rgba(97, 97, 97, 0.9);
  --lt-text-dark-gray: rgba(0, 0, 0, 0.87);
  --lt-mui-toobar-shadow: 0 4px 12px 0 rgba(0,0,0,0.07),0 2px 4px rgba(0,0,0,0.05);
  --mui-tab-text-label:#515D69;
  --lt-link:#357677;
  /* --ff-bold: 'Inter-Bold';
  --ff-semiBold: 'Inter-SemiBold';
  --ff-light: 'Inter-Light';
  --ff-regular: 'Inter-Regular';
  --ff-medium: 'Inter-Medium'; */
}

.pointer{cursor: pointer;}
.no-drop {cursor: no-drop;}
.text-center{text-align: center;}
.card-header h5,h4, h3, h2,h1{
  font-size: 24px;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.h2, h2 {
  font-size: 1.35rem;
}

.modal-open {
  overflow: hidden;
}

.card-shadow{
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.required-label{
  color: var(--lt-danger);
}
.form-group .form-label{
  font-weight: 700;
  text-transform: none;
  font-size: 0.875rem !important;
  color: var(--lt-dark-blue) !important
}

/* side menu layout start */
.menu-links {
  margin-top: 30px;
}
.left-menu {
  background-color: #000;
  padding: 15px;
  height: 100vh;
  color: var(--lt-light);
  position: relative;
}
.menu-links ul li a {
  padding: 7px 10px;
  text-decoration: none;
  display: block;
  color: var(--lt-light);
}
.menu-active {
  background: var(--lt-gray);
  border-radius: 5px;
}
/* side menu layout end */
.acc-main {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #edebe8;
}
.header-details {
  background-color: #ffffff;
  padding: 0 15px 0 20px !important;
  position: sticky;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 6px #00000009;
}
.header-details h5, .header-details p{margin: 0;}

.left-menu-col {
  width: 260px;
  background-color: #f0f0f0;
}
.right-routers-col {
  flex: 1;
  width: calc(100% - 260px);
  padding: 0px;
  overflow-y: auto;
}
/* account dash start */

.router-container {
  padding: 10px 15px 10px 20px;
}
.footre-lnks {
  position: absolute;
  bottom: 10px;
  font-size: 13px;
  color: #9d9d9d;
}
.footre-lnks a {
  /* text-decoration: none; */
  color: #9d9d9d;
  padding-right: 10px;  
}
/* sidemenu header end */

.spacer{
  flex: 1 1 0%;
}
.link-href {
  color: var(--lt-link);
}

.loading{
  width: 22px;
  height: auto;
  opacity: 0.3;
}



/* input lable animation start */
.form-item{
  position: relative;
  margin-bottom: 25px;
  width: 100%;
}
.form-item input{
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  border: solid 1px #d4d4d4;
  transition: all .3s ease;
  padding: 0 15px;
  color:white;
  border-radius: 5px;
}
.form-item input:focus{
  border-color: #787879
}
.form-item label{
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 10px;
  left: 10px;
  font-size: 15px;
  font-weight: bold;
  background: #504545;
  padding: 0 5px;
  color: #999;
  transition: all .3s ease;
  font-weight: 500;
}
.form-item input:focus + label,
.form-item input:valid + label{
  font-size: 13px;
  top: -8px
}
.form-item input:focus + label{
  color: #787879
}
/* input lable animation end */

.card-forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #504545;
  width: 100%;
  height: 520px;
  border-radius: 30px;
  padding: 10px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
  color: white;
}

.capitalize-first-letter::first-letter {
	text-transform: uppercase;
}

input::placeholder{
  font-size: 14px;
}

/* BELOW_CODE_FOR_ELLIPSE_TWO_LINE */
.ellipsis-2l {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit the number of lines to 2 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
}