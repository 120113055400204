section.register-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
}
.register {
    background: #504545;
    min-height: 520px;
    border-radius: 30px;
    padding: 24px 50px 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}
.reg-input-container {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    margin-top: .8rem;
   
}
.reg-input {
    padding: 10px 15px;
    background: transparent;
    border: none;
    width: 100%;
    margin: 10px auto;
    border: 1px solid white;    
    border-radius: 8px;
    padding-left: 8px;
    color: white;
}
*:focus {
    outline: none;
}

.text-error{
    color: var(--lt-danger);
}

@media (max-width: 992px) {
    .register {
        border-radius: 30px;
        padding: 24px 20px;
        margin-top: 150px;
        margin-bottom: 20px;
    } 
}
.register .logo-img {
    padding-top: 0%;
}

.register-sec .sc-input-err-msg{
    font-size: 12px !important;
    color: var(--lt-danger);
}

