.form-control-cust {
    border: solid 1px #979797;
    width: 100%;
    display: block;
    background: transparent;
    padding: 5px 10px;
    margin: 5px 0 15px;
}
.save-btn {
    border-radius: 4px;
    background-color: #000;
    color: white;
    padding: 7px 20px;
    font-weight: 500;
    -webkit-text-stroke: .1px;
    margin-top: 1rem;
    transition: .5s;
}
.save-btn:hover {
    background-color: #767676;
    transition: .4s;
}
.cancel-btn {
    border-radius: 4px;
    background-color: #f17e7e;
    color: white;
    padding: 7px 20px;
    font-weight: 500;
    -webkit-text-stroke: .1px;
    margin-top: 1rem;
    transition: .5s;
}

.scrollable-textarea {
    /* max-height: 300px; Adjust as needed */
    overflow-y: auto; /* Adds vertical scroll if content exceeds max height */
    width: 100%;
    resize: none; /* Optional: Disable resizing to prevent layout shifts */
  }
  
  /* WebKit-based browsers */
  .scrollable-textarea::-webkit-scrollbar {
    width: 8px; /* Adjust width of the scrollbar */
  }
  
  .scrollable-textarea::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 10px; /* Rounded corners for the scrollbar */
  }
  
  .scrollable-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
  }
  
  .scrollable-textarea::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background color of the scrollbar track */
  }
  
  