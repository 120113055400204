.toast-dialog {
    position: fixed;
    top: 25%; /* Adjust this value to move the dialog higher or lower */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Aligns the dialog towards the top */
    background-color: rgba(0, 0, 0, 0.01);
    z-index: 9999999;
    animation: fadeIn 0.3s ease-out;
}

.toast-dialog-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999999;
    animation: zoomIn 0.3s ease-out;
    padding-bottom: 0;
    height: auto;
    padding-top: 15px;
    position: relative;
}

.toast-dialog-content.success {
    background: var(--lt-success) !important;
}

.toast-dialog-content.danger {
    background: var(--lt-danger) !important;
}

.toast-dialog-content.warn {
    background: var(--lt-warn) !important;
}

@keyframes zoomIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.toast-dialog-content p {
    margin-bottom: 20px;
}

.toast-dialog-content button {
    margin-right: 10px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    line-height: 24px;
    transform: rotate(45deg);
    cursor: pointer;
}

.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.align-items-center {
    align-items: center;
}

.checkmark-container {
    margin-right: 15px;
}

.message-container {
    flex: 1;
}

.toast-title {
    font-size: 18px;
    font-weight: 900;
    color: var(--lt-light);
    margin: 0;
}

.toast-message {
    color: var(--lt-light);
    margin: 0;
}