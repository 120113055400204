.svg-box {
    display: inline-block;
    vertical-align: top;
    opacity: 1;
    overflow: visible;
}

@keyframes tick {
    0% {
        stroke-dashoffset: 16px;
        opacity: 1;
    }

    100% {
        stroke-dashoffset: 31px;
        opacity: 1;
    }
}

@keyframes circle-outline {
    0% {
        stroke-dashoffset: 72px;
        opacity: 1;
    }
    100% {
        stroke-dashoffset: 0px;
        opacity: 1;
    }
}

@keyframes circle-fill {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.svg-box .tick {
    fill: none;
    stroke-width: 1px;
    stroke: #000;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: -14px;
    animation: tick 450ms ease 1400ms forwards;
    opacity: 0;
}

.svg-box .success-tick {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg-box .error-tick {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg-box .primary-tick {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg-box .warn-tick {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg-box .warn-tick {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg-box .circle-outline {
    fill: none;
    stroke-width: 1px;
    stroke: #ddd;
    stroke-dasharray: 72px, 72px;
    stroke-dashoffset: 72px;
    animation: circle-outline 300ms ease-in-out 800ms forwards;
    opacity: 0;
}

.svg-box .success-circle-outline {
    stroke: #81c038;
}

.svg-box .error-circle-outline {
    stroke: var(--lt-danger);
}

.svg-box .primary-circle-outline {
    stroke: var(--lt-primary);
}

.svg-box .warn-circle-outline {
    stroke: var(--lt-warn);
}

.svg-box .circle-fill {
    fill: #ddd;
    stroke: none;
    opacity: 0;
    animation: circle-fill 300ms ease-out 1100ms forwards;
}

.svg-box-refeshed, .svg-box-refeshed .circle-fill{
    fill: transparent;
    opacity: 0;
    stroke: none;
}

.svg-box .success-circle-fill {
    fill: #81c038;
}

.svg-box .warn-circle-fill {
    fill: var(--lt-warn);
}

.svg-box .error-circle-fill {
    fill: var(--lt-danger);
}

.svg-box .primary-circle-fill {
    fill: var(--lt-primary);
}

.svg-box .light-circle-fill {
    fill: var(--lt-light);
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .svg-box .tick {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        animation: none;
        opacity: 1;
    }

    .svg-box .circle-outline {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        animation: none;
        opacity: 1;
    }

    .svg-box .circle-fill {
        animation: none;
        opacity: 1;
    }
}