.llm-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
}

.question-card {
  background-color: #f9f9f9;
}

.answer-card {
  background-color: #ffffff;
}

.no-answer-card {
  background-color: #f1f1f1;
  text-align: center;
}


.horizontal-scroll-text {
  white-space: nowrap;
  overflow-x: hidden;
  padding-left: 10px;
  margin-left: 0;
  text-overflow: ellipsis;
}

.horizontal-scroll-text-full {
  padding: 10px 18px;
  margin-left: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: 8px 8px 16px #bebebe,  /* Darker shadow */
              -8px -8px 16px #ffffff; /* Lighter shadow for the 3D effect */
  border-radius: 8px;
  cursor: pointer;
  z-index: 1000;
  white-space: normal;
  width: calc(50% - 60px);
  margin-left: 30px;
  transition: all 0.3s ease; /* Smooth transition */
}