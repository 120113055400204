/* circle loader */
.circle-loader {
    display: block;
    margin: 20px auto;
  }
  
  .circle-loader-background {
    fill: none;
    stroke: #ddd; /* Background color */
  }
  
  .circle-loader-progress {
    fill: none;
    stroke: #007bff; /* Progress color */
    transition: stroke-dashoffset 0.3s ease-out; /* Smooth animation */
  }
  
  .circle-loader-text {
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-anchor: middle;
    dominant-baseline: middle;
    fill: #333; /* Text color */
  }