.containerr {
  display: inline-table;
  background-color: var(--lt-bg-dynamic);
  max-height: 100%;
  height: 100vh;
  width: 100%;
}

.fullPage{
  padding-top: 16px;
}

.compliance-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 7px;
}

.overview-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}



.document-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}

.securitycard-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}