.review-container {
    height: 100%;
    background: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    padding-left: 24px;
}

.review-divider {
    height: 1px;
    margin: 13px;
    background: #D8D8D8;
}

.back-button {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 14px;
    margin: 13px;
}

.account-container {
    display: flex;
    margin: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
border: none;
    padding: 12px;
    background: white;
    border-radius: 4px;
}

.account-container p {
    color: #9D7777;
}

.account-container h3 {
    color: #9D7777;
}

.account-name-container {
    /* flex: 1.8; */
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.account-name-container p {
    font-size: 14px;
}

.account-name-container h3 {
    font-size: 18px;
}

.main-container {
    /* margin-left: 12px; */
}

.account-name {
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.account-progress-container {
    flex: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 32px;
}

.options-container {
    display: flex;
    margin-left: 12px;
}

.options-navigator {
    display: flex;
    margin-right: 12px;
    margin-bottom: 5px;
    background: #edebe8;
    padding: 20px 10px 0;
    z-index: 999;
}

.options-item {
    /* padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 20px; */
    /* display: flex; */
    /* align-items: center; */
    /* height: 40px !important; */
    margin-right: 30px;
    cursor: pointer;
}

.options-item h4{
    font-size: 14px !important;
    margin: 0 !important;
}

.options-tag {
    color: white;
    background: #868585;
    margin-left: 12px;
    border-radius: 14px;
    padding: 6px 6px;
    height: 20px;
    min-width: 20px;
    display: inline-block;
    line-height: .7;
    font-size: 12px;
    position: relative;
    top: -5px;
}

.options-tag span {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.tabActive {
    border-bottom:  4px solid black;
    margin-right: 30px;
}

.questions-container {
    margin-top: 30px;
}

.question-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding: 10px;
    border: none;
    margin-bottom: 12px;
    cursor: grab;
    border-radius: 4px;
    background: white;
}

.question-item-active {
    width: 100%;
    /* height: 18px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding: 10px;
    border: none;
    margin-bottom: 12px;
    cursor: grab;
    background: black;
    border-radius: 4px;
}

.question-item p {
    display: inline;
    white-space: nowrap;
    /* text-overflow: ellipsis; */
}

.question-text {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    display: inline-block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: calc(2.5 * 1.2em);
    overflow-y: hidden; /* Hide scrollbar by default */
    transition: overflow 0.2s ease-in-out; /* Smooth transition */
}

.question-text-white {
    color: white;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    display: inline-block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: calc(2.5 * 1.2em);
    overflow-y: hidden; /* Hide scrollbar by default */
    transition: overflow 0.2s ease-in-out; /* Smooth transition */
}

.question-text:hover,  .question-text-white:hover {
    overflow-y: auto; /* Show scrollbar on hover */
}

.white-text {
    color: white;
    font-size: 14px;
}

.normal {
    font-size: 14px;
}

.answers-container {
    margin-left: 10px;
}

.answers-container textarea {
    padding: 12px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #979797;
}

.save-comment {
    background: #71D115;   
    border-radius: 6px;
    padding: 6px 30px;
    color: white;
    text-align: center;
    font-weight: 500;
    transition: .4s;
}
.save-comment:hover {
    background: #5eb80a; 
    box-shadow: 1px 3px 5px #00000030;
    transition: .4s;
}

/* .review-download-button {
    padding: 14px 35px;
    border-radius: 4px;
    color: white;
    font-size: 20px;
    /* width: 160px; */
    /* background: black;
    text-align: center;
    transition: .4s;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;
    right: 0; */
/* } */
/* .review-download-button:hover {
    background: #414141; 
    box-shadow: 1px 3px 5px #00000030;
    transition: .4s;
}  */

.account-heading{
    margin: 0;
}
.back-arrow {
    width: 16px;
    margin-right: 10px;
    border: none;
    padding: 7px 6px;
    box-sizing: initial;
    border-radius: 5px;
}
.back-arrow:hover {
    background-color: #c5c2c2;
    transition: .4s;
}


.commented-list {
    max-height: 400px;
    overflow-y: auto;
    padding-left: 10px;
}
.commented-box {
    margin: .5rem 0 .5rem;
}
.commented-box img {
    width: 20px;
    margin-right: 6px;
}
.commented-box {
    font-size: 14px;
    border-bottom: solid 1px #d9d9d9;
    padding-bottom: .5rem;
}
.internal {
    background-color: #cfcece;
    color: #000;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 500;
}
.last-modif{
    font-size: 12px;
    margin-left: 10px;
    color: #999;
}
.questions-lists {
    height: calc(100vh - 300px);
    overflow-y: auto;
    scrollbar-width: 10px;
}
::-webkit-scrollbar {
    /* width: 7px;  Commented out to fix trust center bug*/ 
    /* height: 50px; */
}

::-webkit-scrollbar-button {
    background: #ffffff
}

::-webkit-scrollbar-track-piece {
    background: #c2c2c2
}
:-webkit-scrollbar-thumb {
    background: #fce3e3
}


/* radio custom */
.radio-customm input[type="radio"]
{
  appearance: none;
}

.radio-customm input[type="radio"] + label
{
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  line-height: 20px;
}

.radio-customm input[type="radio"] + label::before
{
  content: "";
  display: inline-block;
  width: 18px;
  aspect-ratio: 1;
    border: none;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
}

.radio-customm input[type="radio"] + label::after
{
  content: "";
  display: inline-block;
  width: 8px;
  aspect-ratio: 1;
    border: none;
  background: #686de0;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
}

.radio-customm input[type="radio"]:checked + label::after
{
  opacity: 1;
}

.my-tabs-enterprise .nav-item{
    padding: 0 !important;
}

.account-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Aligns items to the top */
    margin: 12px;
    padding: 16px;
    background: #f5f5f5;
    border-radius: 8px;
    border: 1px solid #d8d8d8;
    height: 85px;
}

.account-name-container {
    /* flex: 2; */
    text-align: left;
    padding-top: 0px;
    margin-top: -2px;
}

.account-name-container p, .account-progress-container h3 {
    font-size: 14px; /* Make the font size the same for both ACCOUNT and PROGRESS */
    margin: 0; /* Removes any extra margin */
    color: #9D7777;
}

.account-progress-container {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Aligns text to the top */
    padding-left: 24px;
    padding-top: 1px;
}

.progress-bar-container {
    width: 100%;
    background-color: #ededed;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px; /* Adds space between the title and the progress bar */
}

.progress-bar {
    background-color: #71d115;
    height: 8px;
    transition: width 0.4s ease;
}

.tab-content .questions-container {
    display: flex;
    flex-direction: column;
    gap: 0px; /* Adds spacing between question items */
    margin-top: 24px;
}

.question-item {
    display: flex;
    align-items: center;
    padding: 12px;
    background: #f9f9f9;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    cursor: pointer;
    min-height: 66px;
}

.question-item-active {
    background-color: #333;
    color: white;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(117, 117, 117, 1); /* Add subtle shadow */
    cursor: pointer;
    min-height: 66px;
}

.save-comment {
    padding: 8px 24px;
    border-radius: 6px;
    background-color: #71d115;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.save-comment:hover {
    background-color: #5eb80a;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}