.homepage-containerr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: white;
    font-family: 'Arial', sans-serif;
}


.homepage-sectionn {
    display: flex ;
    flex-direction: column;
    
    align-items: center;
    background: #504545;
    width: 600px;
    height: 600px;
    border-radius: 30px;
    padding: 10px;
    position: relative;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
    margin-top: -100px;
}

.logo-img{
    padding-top: 3%;
    
}

.provision{
    padding-top: 30px;
}