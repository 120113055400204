.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white !important;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
  