.files-container {
    margin-top: 40px;
    margin-bottom: 60px;
}

.files-container h2 {
    margin-bottom: 18px;
    font-size: 1.8em;
}

.list-container {
    display: flex;
    
}

.icon-container {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    margin-right: 32px;
    flex: 1 1;
}

.list-item p {
    font-size: 0.9em;
    text-align: center;
    flex: 1;
    /* white-space: nowrap; */
}

.upload-text-container {}

.upload-text-container p {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 0;
    line-height: 1.3;
}
.upload-text-container:hover p { 
    color: var(--lt-light-blue);
    transition: .4s;
}


.dummy-loading-file{
    height: 80px;
    width: 80px;
    border-radius: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 18px #aeaeae;
    margin-top: 18px;
}

.ent-delete-file{
    display: inline-block;
    width: 25px;
    text-align: center;
    height: 25px;
    cursor: pointer;
}

.error-popup{
    background: #e02f60;
    min-width: 450px;
    border-radius: 6px;
    color: #fff;
}

.row-style{
    width: calc(100% - 160px);
}