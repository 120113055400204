.provision-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  

}
.screen-title{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px !important;
    font-weight: 300;
    text-align: center;
    
}

.loadinggg{
    padding-top: 30px;
}