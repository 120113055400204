button {
    all: unset;
}

.enterprise-container {
    /* height: 100vh; */
    background: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    padding-left: 20px;
}

.main {
    display: flex;
}

.main-left {
    flex: 1 1;
}

.main-right {
    flex: 1 1;
}

.heading-container {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.heading-container h2 {
    font-size: 1.8em;
    color: #00A8F8;
    margin-right: 12px;
}

/* .vendor-heading {
    color: #00A8F8;
} */

.searchbar {
    border: 1px solid black;
    padding-left: 8px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 6px;
    display: flex;
    width: 40%;
}

.searchbar-input {
    border: none;
    width: 100%;
    font-size: 1.1em;
}

.divider {
    width: 1px;
    height: 100vh;
    background: gray;
    margin-left: 30px;
    margin-right: 30px;
}

.horizontal-divider {
    height: 1px;
    background: black;
}


.right-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    flex-direction: column;
}

.status-container {
    margin: 10px 0;
}

.status-container p {
    text-align: center;
    font-size: 18px;
}

.analysis-container {
    margin-bottom: 36px;
}

.analysis-container p {
    text-align: center;
}

.incomplete-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 36px;
}

.buttons-container button {
    padding: 14px;
    border-radius: 4px;
    color: white;
    font-size: 20px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.suggestions-button {
    background: #FDD57F;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    border-radius: 4px;
}

.review-button {
    background: #3DAE64;
}

.download-button {
    background: black;
}


.circle-loader {
    display: block;
    margin: 0 auto;
}

