.svg2-box {
    display: inline-block;
    vertical-align: top;
    opacity: 1;
    overflow: visible;
}

.svg2-box .tick2 {
    fill: none;
    stroke-width: 1px;
    stroke: #000;
    stroke-dasharray: 15px, 15px;
    stroke-dashoffset: 0;
    opacity: 1;
}

.svg2-box .success-tick2 {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg2-box .error-tick2 {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg2-box .primary-tick2 {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg2-box .warn-tick2 {
    stroke: var(--lt-light);
    stroke-width: 0.14rem;
}

.svg2-box .circle-outline2 {
    fill: none;
    stroke-width: 1px;
    stroke: #ddd;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    opacity: 1;
}

.svg2-box .success-circle-outline2 {
    stroke: #81c038;
}

.svg2-box .error-circle-outline2 {
    stroke: var(--lt-danger);
}

.svg2-box .primary-circle-outline2 {
    stroke: var(--lt-primary);
}

.svg2-box .warn-circle-outline2 {
    stroke: var(--lt-warn);
}

.svg2-box .circle-fill2 {
    fill: #ddd;
    stroke: none;
    opacity: 1;
}

.svg2-box .success-circle-fill2 {
    fill: #81c038;
}

.svg2-box .warn-circle-fill2 {
    fill: var(--lt-warn);
}

.svg2-box .error-circle-fill2 {
    fill: var(--lt-danger);
}

.svg2-box .primary-circle-fill2 {
    fill: var(--lt-primary);
}

.svg2-box .light-circle-fill2 {
    fill: var(--lt-light);
}

.svg2-box-refeshed2, .svg2-box-refeshed2 .circle-fill2 {
    fill: transparent;
    opacity: 0;
    stroke: none;
}