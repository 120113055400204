.people-sec {
    border-bottom: solid 1px #ddd;
    padding: 5px 15px;
    font-size: 14px;
    transition: .4s;
}
.people-sec:hover {
    background-color: #f7f7f7;
    transition: .4s;
}
.people-sec:last-child {
    border-bottom: 0;
}
.avtar-img {
    width: 40px;
    height: 40px;
    border: solid 1px #ddd;
    border-radius: 60px ;
}
.people-name {
    font-weight: 600;
    color: var(--lt-light-blue);
    margin: 9px 0 0 10px;
    line-height: .8;
}
.people-desc {
    margin: 0 0 0 10px;
    color: #7c7c7c;
    font-size: 14px;
}
.PendingInvite {
    color: #8f8f8f;
    font-size: 15px;
    margin-top: 13px;
}
.email-sec {
    color: #8f8f8f;
    font-size: 14px;
    margin-top: 13px;
}
.collaborate-btn {
    background-color: #f1f1f1;
}