.file_custom-file img {
    width: 16px;
    height: 16px;
}

#upload_file {
    display: none;
}

.file_btn-bg {
    background: #024181;
    color: #fff;
}

.file_btn-bg:hover {
    background: #0260be;
    color: #fff;
    cursor: pointer;
}

.file_btn {
    -moz-user-select: none;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 6px 10px;
    transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    vertical-align: middle;
    white-space: nowrap;
}

.gray-color {
    color: rgb(104, 103, 103);
}