.pgetileright {
    font-size: 17px;
    font-weight: 600;
    line-height: 120%;
    margin-left: 5px;

}

.pgetilerleft {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    margin-left: 5px;
}

.lefticon {
    margin-top: -4px;
}

/* #efeded; */
.pgcard {
    border: 1px solid #e4e3e3;
    padding: 8px;
}

.tblehd {
    color: white;
    background-color: #ddd;
}

.headtl {
    font-size: 14px;
    font-weight: 200 !important;
}

.thtl {
    font-size: 14px;
    font-weight: 600 !important;
}

.tbl-search-main {
    margin-bottom: 10px;
}

.acc-search {
    padding-left: 30px;
    border: solid 1px #ddd;
    width: 100%;
    border-radius: 4px;
    line-height: 2.33;
}

.acc-search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
}

.searchTbl {
    width: 80%;
}

.fiterBtn button {
    padding: 5px 13px;
}

.dataRqst button:hover,
.fiterBtn button:hover {
    background-color: #f5f5f5;
}

.btn_tick {
    background: #dafce0;
    border-radius: 4px;
    padding: 6px 5px;
    margin-right: 5px;
    cursor: pointer;
}

.btn_cros {
    background: #fcc7c9;
    border-radius: 4px;
    padding: 6px 5px;
    margin-right: 5px;
    cursor: pointer;
}

.btn_exr {
    cursor: pointer;
}