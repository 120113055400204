* {
    font-family: Arial, Helvetica, sans-serif;
}

h1{
    color:white;
}


.watermark {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 120px;
}

.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: white;
    font-family: 'Arial', sans-serif;
}

@media (max-width: 768px) {
    .homepage-section {
        background: #504545;
        width: 95%;
        height: 435px!important;
    }
    .input-container {
        width: 100%!important;
    }
    .homepage-container {
        margin-left: 0;
    }
    .logo-img {
        width: 25vw!important;
    }
    .section-wrapper h1 {
        margin-top: 25px!important;
        margin-bottom: 23px!important;
    }
    .links-container {
        display: inline-block !important;
        width: 85% !important;
        padding-bottom: 2rem;
    }
    .logo-img {
        padding-top: 0%!important;
    }
}

.section-wrapper h1 {
    margin-top: 48px;
    margin-bottom: 28px;
}

input::placeholder {
    color: #6E7780;
}

.logo-img {
    width: 10vw;
    margin-bottom:-3px;
}

.input {
    color: white;
    background: transparent;
    border: none;
    width: 100%;
    box-shadow: none;
    outline: none;
}

.next-button2 {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
}

.white-icon {
    color: white;
}

.checkbox-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.checkbox {
    width: 10px;
    margin-right: 10px;
}

.submit-container {
    margin-top: 1em;
}

.submit-button {
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
    background-color: gray;
    color: white;
    cursor: pointer;
    font-size: 1em;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #e56757;
}

.homepage-section {
    display: flex ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #504545;
    width: 100%;
    height: 520px;
    border-radius: 30px;
    padding: 10px;
    position: relative;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}

.form-container {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.links-container {
    display: flex;
    justify-content: space-between;
    width: 85%;   
}

.forgotpass-link, .signup-link {
    color: #999;
}

.section-wrapper {
    margin-top: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 85%;    
}

.input-container {    
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;    
    width: 95%;
}

