.confirmation-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    animation: fadeIn 0.3s ease-out;
}

.confirmation-dialog-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999999;
    animation: zoomIn 0.3s ease-out;
    padding-bottom: 0;
}

@keyframes zoomIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.confirmation-dialog-content p {
    margin-bottom: 20px;
}

.confirmation-dialog-content button {
    margin-right: 10px;
}